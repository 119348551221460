import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const ContnetfulPage = ({ data, location }) => {
  const page = data.contentfulPage;
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <div
        className="prose lg:prose-xl dark:prose-invert flex flex-col min-h-screen py-12"
        dangerouslySetInnerHTML={{
          __html: page.content.childMarkdownRemark.html,
        }}
      />
    </Layout>
  );
};
export const Head = ({ data }) => {
  const page = data.contentfulPage;
  const ogPublishedAt = new Date(page.createdAt);
  const ogModifiedAt = new Date(page.updatedAt);
  const siteTitle = data.site.siteMetadata.title;
  return (
    <>
      <SEO
        title={page.title || siteTitle}
        ogImageUrl={`https:${page.image?.file?.url}`}
        slug={page.slug}
        description={
          page.description || page.content.childMarkdownRemark.excerpt
        }
      />
      <meta property="article:section" content="Design" />
      <meta property="article:tag" content="Figma" />
      <meta property="article:tag" content="Contentful" />
      <meta
        property="article:published_time"
        content={ogPublishedAt.toISOString()}
      />
      <meta
        property="article:modified_time"
        content={ogModifiedAt.toISOString()}
      />
    </>
  );
};
export const query = graphql`
  query ContentfulPageQuery($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(id: { eq: $id }) {
      description
      content {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 160)
        }
      }
      title
      slug
      updatedAt(formatString: "MMMM DD, YYYY")
      createdAt(formatString: "MMMM DD, YYYY")
    }
  }
`;
export default ContnetfulPage;
